import { handleApiError, handleApiResponse } from "@/methods/api";
import ApiVerified from "@/services/apiVerified";
import {
  ConductTestInfo,
  ConductTestState,
  ConductTestStore,
  TestEvent,
  TestTimeStamps,
} from "@/types/conductTest";
import { ApiResponse, IdNamePair } from "@/types/global";
import {
  AffiliationTestReport,
  AffiliationTestReportDetails,
  AllInstituteTestStudentMarks,
  BatchInstituteTestSummary,
  InstituteTestTemplate,
  ManualMarksData,
  PreloadedTestInfo,
} from "@/types/instituteTest";
import {
  OmrReaderConfig,
  OmrResult,
  OmrTemplate,
  QuestionPaperTemplate,
} from "@/types/offlineTest";
import { SectionGroup, SelectorQuestion } from "@/types/questionSelector";
import { TestReport, TestReportDetails } from "@/types/studentTestReport";

import { PdfReportDisplay } from "@/types/affiliation";
import {
  InstituteTestData,
  InstituteTestDesign,
  InstituteTestStudent,
} from "@/types/instituteTest";
import { SelfTestCreate, SelfTestDisplay, SelfTestTemplate } from "@/types/selfTest";

export default class TestApi extends ApiVerified {
  async getSelfTestTemplates(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<SelfTestTemplate>>(
        `self-test/get-test-templates`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createSelfTest(testData: SelfTestCreate): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<number>(`self-test/create-test`, testData);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSelfTestsForStudent(userId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<SelfTestDisplay>(
        `self-test/get-tests-for-student`,
        {
          params: { userId: userId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async loadTest(testId: number, testType: string, userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<ConductTestStore>(`conduct-test/load-test`, {
        params: { testId: testId, testType: testType, userId: userId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async startTest(
    testInstanceId: number,
    testType: string,
    duration: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<TestTimeStamps>(`conduct-test/start-test`, {
        testInstanceId: testInstanceId,
        testType: testType,
        duration: duration,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async pushUnsavedTestEvents(
    testInstanceId: number,
    testType: string,
    testEvents: Array<TestEvent>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`conduct-test/push-test-events`, {
        testInstanceId: testInstanceId,
        testType: testType,
        testEvents: testEvents,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endTest(testInfo: ConductTestInfo, testState: ConductTestState): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`conduct-test/end-test`, {
        TestInfo: testInfo,
        TestState: testState,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentTestReport(
    testInstanceId: number,
    testType: string,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<TestReport>(
        `conduct-test/student-test-report`,
        {
          params: {
            testInstanceId: testInstanceId,
            testType: testType,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentTestReportDetails(
    testInstanceId: number,
    testType: string,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<TestReportDetails>(
        `conduct-test/student-test-report-details`,
        {
          params: {
            testInstanceId: testInstanceId,
            testType: testType,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async resetStudentTestAttempts(
    testId: number,
    testType: string,
    studentId: number,
    affiliationId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(`conduct-test/reset-student-test`, {
        params: {
          testId: testId,
          testType: testType,
          studentId: studentId,
          affiliationId: affiliationId,
          userId: userId,
          userRole: userRole,
        },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async resetAllTestAttempts(
    testId: number,
    testType: string,
    affiliationId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(
        `conduct-test/reset-all-test-attempts`,
        {
          params: {
            testId: testId,
            testType: testType,
            affiliationId: affiliationId,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAvailableTestQuestionsForChapter(
    courseChapterId: number,
    questionTypeId: number,
    onlyActive: boolean,
    exclusionBatchIdsCsv: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<Array<SelectorQuestion>>(
        `institute-test/get-available-questions-for-chapter`,
        {
          courseChapterId: courseChapterId,
          questionTypeId: questionTypeId,
          onlyActive: onlyActive,
          exclusionBatchIdsCsv: exclusionBatchIdsCsv,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTestAnswerKeyPdf(instituteTestId: number, affiliationId: number) {
    try {
      const response = await this.axiosInstance.get<string>(
        `institute-test/get-test-answer-key-pdf`,
        {
          params: {
            instituteTestId: instituteTestId,
            affiliationId: affiliationId,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTestSolutionsPdf(instituteTestId: number, affiliationId: number) {
    try {
      const response = await this.axiosInstance.get<string>(
        `institute-test/get-test-solutions-pdf`,
        {
          params: {
            instituteTestId: instituteTestId,
            affiliationId: affiliationId,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async generateTestQuestionPdf(
    instituteTestId: number,
    affiliationId: number,
    templateName: string,
    groupQuestionTypes: boolean,
    forceRegenerate: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(
        `institute-test/generate-test-question-pdf`,
        {
          params: {
            instituteTestId: instituteTestId,
            affiliationId: affiliationId,
            templateName: templateName,
            groupQuestionTypes: groupQuestionTypes,
            forceRegenerate: forceRegenerate,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getOmrTemplatesForTest(
    instituteTestId: number,
    numericalAsInteger: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<OmrTemplate>>(
        `institute-test/get-test-omr-templates`,
        { params: { instituteTestId: instituteTestId, numericalAsInteger: numericalAsInteger } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async generateTestOmrImage(
    instituteTestId: number,
    batchId: number,
    templateName: string | null,
    groupQuestionTypes: boolean,
    numericalAsInteger: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<string>(
        `institute-test/generate-test-omr-image`,
        {
          instituteTestId: instituteTestId,
          batchId: batchId,
          templateName: templateName,
          groupQuestionTypes: groupQuestionTypes,
          numericalAsInteger: numericalAsInteger,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestsForBatch(batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `institute-test/get-institute-tests-for-batch`,
        {
          params: { batchId: batchId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveInstituteTestTemplate(
    instituteTestTemplate: InstituteTestTemplate,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`institute-test/save-test-template`, {
        testTemplate: instituteTestTemplate,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestDesign(
    instituteTestId: number,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<InstituteTestDesign>(
        `institute-test/get-test-design`,
        {
          params: { instituteTestId: instituteTestId, affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestInstructions(instituteTestId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<InstituteTestDesign>(
        `institute-test/get-test-instructions`,
        { params: { instituteTestId: instituteTestId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveInstituteTestInstructions(
    instituteTestId: number,
    affiliationId: number,
    testInstructions: Array<string>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/save-test-instructions`,
        {
          instituteTestId: instituteTestId,
          affiliationId: affiliationId,
          testInstructions: testInstructions,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestsForAffliation(
    affiliationId: number,
    category: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<InstituteTestData>(
        `institute-test/get-tests-for-affiliation`,
        {
          params: { affiliationId: affiliationId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getInstituteTestsForStudent(userId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<InstituteTestStudent>(
        `institute-test/get-tests-for-student`,
        {
          params: { userId: userId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteInstituteTestTemplate(
    mongoId: string,
    courseId: number,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(
        `institute-test/delete-test-template`,
        { params: { mongoId: mongoId, courseId: courseId, affiliationId: affiliationId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createInstituteTest(
    testDesign: InstituteTestDesign,
    affiliationId: number,
    courseId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/create-institute-test`,
        {
          testDesign: testDesign,
          affiliationId: affiliationId,
          courseId: courseId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async rescheduleInstituteTest(
    instituteTestId: number,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/reschedule-institute-test`,
        {
          instituteTestId: instituteTestId,
          batchIds: batchIds,
          startDatetime: startDatetime,
          endDatetime: endDatetime,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async cloneInstituteTest(
    instituteTestId: number,
    testName: string,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/clone-institute-test`,
        { instituteTestId: instituteTestId, testName: testName, affiliationId: affiliationId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async publishInstituteTest(
    instituteTestId: number,
    affiliationId: number,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/publish-institute-test`,
        {
          instituteTestId: instituteTestId,
          affiliationId: affiliationId,
          batchIds: batchIds,
          startDatetime: startDatetime,
          endDatetime: endDatetime,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteInstituteTest(
    instituteTestId: number,
    affiliationId: number,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(
        `institute-test/delete-institute-test`,
        {
          params: {
            instituteTestId: instituteTestId,
            affiliationId: affiliationId,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async renameInstituteTest(instituteTestId: number, testName: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/rename-institute-test`,
        {
          instituteTestId: instituteTestId,
          testName: testName,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveTestQuestions(
    instituteTestId: number,
    affiliationId: number,
    sectionGroups: Array<SectionGroup>,
    finalizeTest: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/save-test-questions`,
        {
          instituteTestId: instituteTestId,
          affiliationId: affiliationId,
          sectionGroups: sectionGroups,
          finalizeTest: finalizeTest,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedTestById(preloadedTestId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<boolean>(
        `institute-test/preloaded-test-by-id`,
        {
          params: { preloadedTestId: preloadedTestId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async migrateMultipleNewTestsToPreloaded(
    instituteTestIds: Array<number> | null,
    preloadedCategoryId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/migrate-multiple-new-tests-to-preloaded`,
        {
          instituteTestIds: instituteTestIds,
          preloadedCategoryId: preloadedCategoryId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async generateOfflineTestReport(
    testName: string,
    academicSession: string,
    csvFile: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<string>(
        `pdf-report/offline-test-subject-wise-report`,
        { testName: testName, academicSession: academicSession, csvFile: csvFile },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async submitOmrsForProcessing(
    instituteTestId: number,
    batchId: number,
    templateName: string,
    fileName: string,
    fileSource: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/submit-omrs-for-processing`,
        {
          instituteTestId: instituteTestId,
          batchId: batchId,
          templateName: templateName,
          fileName: fileName,
          fileSource: fileSource,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getOmrUploadsByStatus(omrUploadStatusId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get(`institute-test/get-omr-uploads-by-status`, {
        params: { omrUploadStatusId: omrUploadStatusId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getOmrResults(
    instituteTestId: number,
    batchId: number,
    omrUploadId: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get(`institute-test/get-omr-results`, {
        params: { instituteTestId: instituteTestId, batchId: batchId, omrUploadId: omrUploadId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getOmrReaderConfig(instituteTestId: number, batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<OmrReaderConfig>(
        `institute-test/get-omr-reader-config`,
        {
          params: { instituteTestId: instituteTestId, batchId: batchId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveEditedOmrResults(omrResults: Array<OmrResult>): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/save-edited-omr-results`,
        omrResults,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async finalizeOmrResults(omrResults: Array<OmrResult>): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/finalize-omr-results`,
        omrResults,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async reprocessOmrUpload(mongoId: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/reprocess-omr-upload`,
        { mongoId: mongoId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteOmrUpload(mongoId: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`institute-test/delete-omr-upload`, {
        mongoId: mongoId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionPaperTemplates(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<QuestionPaperTemplate>>(
        `institute-test/get-question-paper-templates`,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationTestReport(
    testId: number,
    testType: string,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationTestReport>(
        `conduct-test/affiliation-test-report`,
        {
          params: {
            testId: testId,
            testType: testType,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAffiliationTestReportDetails(
    testId: number,
    testType: string,
    userId: number,
    userRole: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AffiliationTestReportDetails>(
        `conduct-test/affiliation-test-report-details`,
        {
          params: {
            testId: testId,
            testType: testType,
            userId: userId,
            userRole: userRole,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTestManualMarksData(instituteTestId: number, batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<ManualMarksData>(
        `institute-test/get-test-manual-marks-data`,
        {
          params: { instituteTestId: instituteTestId, batchId: batchId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveTestManualMarksData(manualMarksData: ManualMarksData): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/save-test-manual-marks-data`,
        { manualMarksData: manualMarksData },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedTestsForAffiliation(
    courseId: number,
    isParentAffiliation: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<PreloadedTestInfo>>(
        `institute-test/preloaded-tests-for-affiliation`,
        { params: { courseId: courseId, isParentAffiliation: isParentAffiliation } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedTestsForChapter(courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `institute-test/preloaded-tests-for-chapter`,
        { params: { courseChapterId: courseChapterId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async publishPreloadedTest(
    testId: number,
    affiliationId: number,
    testName: string,
    batchIds: Array<number>,
    startDatetime: string,
    endDatetime: string,
    teachingAssessmentId: number | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `institute-test/publish-preloaded-test`,
        {
          testId: testId,
          affiliationId: affiliationId,
          testName: testName,
          batchIds: batchIds,
          startDatetime: startDatetime,
          endDatetime: endDatetime,
          teachingAssessmentId: teachingAssessmentId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPreloadedTestsForStudent(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<PreloadedTestInfo>>(
        `institute-test/preloaded-tests-for-student`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStandardTestTemplatesForCourse(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<InstituteTestTemplate>>(
        `institute-test/standard-test-templates-for-course`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSavedTestTemplatesForCourse(
    courseId: number,
    affiliationId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<InstituteTestTemplate>>(
        `institute-test/saved-test-templates-for-course`,
        { params: { courseId: courseId, affiliationId: affiliationId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAllTestsSummaryForBatch(batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<BatchInstituteTestSummary>>(
        `affiliation-report/get-all-tests-summary-for-batch`,
        { params: { batchId: batchId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAllTestsStudentMarksForBatch(batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<AllInstituteTestStudentMarks>(
        `affiliation-report/get-all-test-student-marks-for-batch`,
        { params: { batchId: batchId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async generateManualMarksPdfReport(
    instituteTestId: number,
    batchId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<string>(
        `institute-test/create-test-manual-marks-pdf-report`,
        { instituteTestId: instituteTestId, batchId: batchId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async generateManualMarksheet(instituteTestId: number, batchId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(
        `institute-test/get-test-manual-marks-marksheet`,
        { params: { instituteTestId: instituteTestId, batchId: batchId } },
      );

      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPdfReportsForAffiliation(
    affiliationId: number,
    reportName: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<PdfReportDisplay>>(
        `pdf-report/get-pdf-reports-for-affiliation`,
        {
          params: {
            affiliationId: affiliationId,
            reportName: reportName,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
}
