import { handleApiError, handleApiResponse } from "@/methods/api";
import ApiVerified from "@/services/apiVerified";
import { ApiResponse, IdNamePair } from "@/types/global";
import {
  CreateLiveClassInputs,
  LiveClassData,
  LiveClassLogsDisplay,
  LiveClassLogsInputs,
} from "@/types/liveClass";
import { CreateLivePracticeInputs, LivePracticeData } from "@/types/livePractice";

export default class LiveClassApi extends ApiVerified {
  async getLiveClassFacultyDetails(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-faculty`,
        {
          params: { affiliationId: affiliationId, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLivePracticeModeratorDetails(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-moderators`,
        {
          params: { affiliationId: affiliationId, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLiveClassesForAffliation(
    affiliationId: number,
    category: number,
    minDatetime: string | null,
    maxDatetime: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<LiveClassData>>(
        `live-class/get-live-classes-for-affiliation`,
        {
          params: {
            affiliationId: affiliationId,
            category: category,
            minDatetime: minDatetime,
            maxDatetime: maxDatetime,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLivePracticesForAffliation(
    affiliationId: number,
    category: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<LivePracticeData>>(
        `live-practice/get-live-practices-for-affiliation`,
        {
          params: { affiliationId: affiliationId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLiveClassesForFaculty(
    userId: number,
    category: number,
    minDatetime: string | null,
    maxDatetime: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<LiveClassData>>(
        `live-class/get-live-classes-for-faculty`,
        {
          params: {
            userId: userId,
            category: category,
            minDatetime: minDatetime,
            maxDatetime: maxDatetime,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLivePracticesForModerator(userId: number, category: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<LivePracticeData>>(
        `live-practice/get-live-practices-for-moderator`,
        {
          params: { userId: userId, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLiveClassesForStudent(
    userId: number,
    userName: string,
    category: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<LiveClassData>>(
        `live-class/get-live-classes-for-student`,
        {
          params: { userId: userId, userName: userName, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLivePracticesForStudent(
    userId: number,
    userName: string,
    category: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<LivePracticeData>>(
        `live-practice/get-live-practices-for-student`,
        {
          params: { userId: userId, userName: userName, category: category },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async checkZoomAccess(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<boolean>(
        `affiliation-batch/check-zoom-access`,
        {
          params: { affiliationId: affiliationId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createLivePractice(liveClassInputs: CreateLivePracticeInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-practice/create-live-practice`,
        liveClassInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async rescheduleLiveClass(
    liveClassId: number,
    startTime: string,
    duration: number,
    includeBasicZoom: boolean,
    allFutureEvents: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-class/reschedule-live-class`, {
        liveClassId: liveClassId,
        startTime: startTime,
        duration: duration,
        includeBasicZoom: includeBasicZoom,
        allFutureEvents: allFutureEvents,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async rescheduleLivePractice(
    livePracticeId: number,
    startTime: string,
    duration: number,
    includeBasicZoom: boolean,
    allFutureEvents: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-practice/reschedule-live-practice`,
        {
          livePracticeId: livePracticeId,
          startTime: startTime,
          duration: duration,
          includeBasicZoom: includeBasicZoom,
          allFutureEvents: allFutureEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async modifyLiveClassSubject(
    liveClassId: number,
    subjectId: number,
    allFutureEvents: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<ApiResponse>(
        `live-class/modify-live-class-subject`,
        {
          liveClassId: liveClassId,
          subjectId: subjectId,
          allFutureEvents: allFutureEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async modifyLiveClassFaculty(
    liveClassId: number,
    facultyId: number,
    allFutureEvents: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-class/modify-live-class-faculty`,
        {
          liveClassId: liveClassId,
          facultyId: facultyId,
          allFutureEvents: allFutureEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async modifyLivePracticeModerator(
    livePracticeId: number,
    moderatorId: number,
    allFutureEvents: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-practice/modify-live-practice-moderator`,
        {
          livePracticeId: livePracticeId,
          moderatorId: moderatorId,
          allFutureEvents: allFutureEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async startLiveClass(liveClassId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-class/start-live-class`, {
        liveClassId: liveClassId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async startLivePractice(livePracticeId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-practice/start-live-practice`, {
        livePracticeId: livePracticeId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endLiveClass(liveClassId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-class/end-live-class`, {
        liveClassId: liveClassId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endLivePractice(livePracticeId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-practice/end-live-practice`, {
        livePracticeId: livePracticeId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateZoomModeratorUrl(liveClassId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-class/update-zoom-moderator-url`,
        { liveClassId: liveClassId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addClassLogs(classLogsInputs: LiveClassLogsInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-class/add-class-logs`,
        classLogsInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getClassLogs(liveClassId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<LiveClassLogsDisplay>(
        `live-class/get-class-logs`,
        {
          params: { liveClassId: liveClassId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async cancelLiveClass(liveClassId: number, allFutureEvents: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`live-class/cancel-live-class`, {
        liveClassId: liveClassId,
        allFutureEvents: allFutureEvents,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async cancelLivePractice(livePracticeId: number, allFutureEvents: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-practice/cancel-live-practice`,
        {
          livePracticeId: livePracticeId,
          allFutureEvents: allFutureEvents,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAssignmentsForLivePractice(batchIdsCsv: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `assignment/get-assignments-for-live-practice`,
        {
          params: { batchIdsCsv: batchIdsCsv },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createLiveClass(liveClassInputs: CreateLiveClassInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `live-class/create-live-class`,
        liveClassInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
}
