<template>
  <div>
    <VueFullscreenWindow
      v-model="fullscreenState"
      :teleport="true"
      v-on:change="fullscreenStateChanged"
    >
      <div
        ref="appRootElement"
        v-if="SpeedlabsConfig.WebVersion !== null"
        class="speedlabs-app ff-default flexcol ystretch"
        :class="appContainerClasses"
      >
        <div v-if="isSessionReady && !fullscreenState && !hideNavbar">
          <TheAppNavbar />
        </div>
        <CustomAlert v-if="AppRefreshRequired" :userAlert="appRefreshAlert" />
        <div v-if="userAlerts !== null">
          <CustomAlert v-for="userAlert in userAlerts" :userAlert="userAlert" />
        </div>
        <router-view
          v-slot="{ Component }"
          class="page-content flexcol ystretch flex-fill"
          :key="$route.fullPath"
        >
          <transition>
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
        <div v-if="!hideFooter && !fullscreenState" class="footer-container">
          <TheAppFooter />
        </div>
        <TheModal />
      </div>
      <div v-else>
        <TheLoadingPage
          loadingImage="assembling-things.svg"
          loadingText="Putting things together"
        />
      </div>
    </VueFullscreenWindow>
  </div>
</template>

<script lang="ts">
import CustomAlert from "@/components/CustomAlert.vue";
import TheAppFooter from "@/components/TheAppFooter.vue";
import TheAppNavbar from "@/components/TheAppNavbar.vue";
import TheLoadingPage from "@/components/TheLoadingPage.vue";
import TheModal from "@/components/TheModal.vue";
import { appRefreshAlert } from "@/constants/global";
import router from "@/router";
import { useSessionStore } from "@/stores/sessionStore";
import { useSpeedlabsStore } from "@/stores/speedlabsStore";
import { storeToRefs } from "pinia";
import { computed, defineComponent, onBeforeMount } from "vue";
import { component as VueFullscreenWindow } from "vue-fullscreen";

export default defineComponent({
  name: "App",
  components: {
    TheLoadingPage,
    TheModal,
    TheAppNavbar,
    TheAppFooter,
    VueFullscreenWindow,
    CustomAlert,
  },
  setup() {
    const speedlabsStore = useSpeedlabsStore();
    const { AppRefreshRequired, SpeedlabsConfig } = storeToRefs(speedlabsStore);
    const sessionStore = useSessionStore();
    const { fullscreenState, hideNavbar, hideFooter, navbarMenu, userAlerts, isSessionReady } =
      storeToRefs(sessionStore);
    const {
      fullscreenStateChanged,
      resetFullscreenState,
      addWindowResizeListener,
      checkActiveSessionValidity,
    } = sessionStore;

    isSessionReady.value = false;

    const appContainerClasses = computed(() => {
      const classNames =
        (fullscreenState.value ? "full-screen-active" : "") +
        (hideFooter.value ? "" : "footer-visible");
      return classNames;
    });

    window.addEventListener("message", (event) => {
      if (event.origin !== import.meta.env.VITE_OLD_VUE_APP_URL) {
        return;
      }
      const oldAppTo = JSON.parse(event.data);
      if (oldAppTo.name === "not-found") {
        router.push({ path: oldAppTo.path });
      } else {
        const newRoutePath = navbarMenu.value?.OldToNewRoute[oldAppTo.name];
        if (newRoutePath) {
          history.pushState({}, "", newRoutePath);
        }
      }
    });

    onBeforeMount(async () => {
      resetFullscreenState();
      addWindowResizeListener();
      if (import.meta.env.MODE === "production") {
        setInterval(async () => {
          await checkActiveSessionValidity();
        }, import.meta.env.VITE_DUPLICATE_LOGIN_TIMEOUT);
      }
    });

    window.oncontextmenu = (e) => {
      e.preventDefault();
    };

    return {
      SpeedlabsConfig,
      AppRefreshRequired,
      isSessionReady,
      hideNavbar,
      hideFooter,
      fullscreenState,
      appContainerClasses,
      appRefreshAlert,
      userAlerts,
      fullscreenStateChanged,
    };
  },
});
</script>

<style lang="scss" scoped>
.speedlabs-app {
  min-height: 100vh;
}

.page-content {
  width: 100%;
}
</style>
