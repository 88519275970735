export enum StudentFiltersEnum {
  TOPPERS = "toppers",
  LAGARDS = "laggards",
  LEAST_PRACTICED = "leastEffort",
  MOST_EFFORT = "mostEffort",
}

export enum ChapterFiltersEnum {
  DEFAULT = "default",
  RECENT = "recent",
  IMPORTANT = "important",
  STRONGEST = "strongest",
  WEAKEST = "weakest",
}

export enum SyllabusCoverageTabsEnum {
  BATCH_WISE = "Batch-wise",
  TEACHER_WISE = "Teacher-wise",
}

export enum DashboardTabsEnum {
  REPORTS = "Reports",
  RECOMMENDATIONS = "Recommendations",
}

export enum PdfReportNamesEnum {
  LEAD_SCHOOL_WEEKLY = 1,
  LEAD_SCHOOL_MONTHLY = 2,
  LEAD_STUDENT_PROGRESS = 3,
  LEAD_SERIES_SCHOOL_MONTHLY = 4,
  LEAD_SERIES_STUDENT_MONTHLY = 5,
  LEAD_SCHOOL_EXAM_RESULTS = 6,
  LEAD_STUDENT_EXAM_RESULTS = 7,
  LEAD_SCHOOL_TEST_RESULTS = 8,
  LEAD_STUDENT_TEST_RESULTS = 9,
  OFFLINE_TEST_SUBJECTWISE_RESULTS = 10,
}
