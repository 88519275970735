import { handleApiError, handleApiResponse } from "@/methods/api";
import ApiVerified from "@/services/apiVerified";
import { ApiResponse, IdNamePair } from "@/types/global";
import { TutoringModule } from "@/types/tutoring";

export default class TutorApi extends ApiVerified {
  async getActiveTutorCourses(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `tutoring/get-active-courses`,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getModulesForTutorCourses(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutoringModule>>(
        `tutoring/get-modules-for-course`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
}
