import { AllAdminRoles, AllManagerRoles, AllStudentRoles } from "@/constants/userRoles";
import { UserRolesEnum } from "@/enums/global";
import { onWebAppLogout, showCustomMessage } from "@/methods/utils";
import router from "@/router";
import ApiUnverified from "@/services/apiUnverified";
import ApiVerified from "@/services/apiVerified";
import { useSpeedlabsStore } from "@/stores/speedlabsStore";
import LiveClassApi from "@/services/liveClassApi";
import {
  ApiResponse,
  LoginWithOtp,
  LoginWithPassword,
  NavbarMenu,
  RegisterCredentials,
  SessionStore,
  UserAlert,
  UserInfo,
} from "@/types/global";
import { defineStore, storeToRefs } from "pinia";
import TestApi from "@/services/testApi";
import ContentApi from "@/services/contentApi";
import TutorApi from "@/services/tutorApi";

const apiUnverified = new ApiUnverified();

function getInitialSessionStore(): SessionStore {
  const defaultUserInfo: UserInfo = {
    UserId: 0,
    UserRoleId: 2,
    UserRole: UserRolesEnum.STUDENT,
    UserName: "",
    UserCode: "",
    EmailId: "",
    Mobile: "",
    AffiliationId: 0,
    CourseId: 0,
    ClassId: 0,
    IsDemoUser: false,
    UserDashboard: "",
    DomainName: "",
    RedirectUrl: "",
    ImageUrl: "",
    SessionUuid: "",
    AccessToken: null,
    RefreshToken: null,
    SLToken: null,
    LoginSessionId: 0,
    AppVersion: null,
  };
  const initApiVerified = {
    apiVerified: new ApiVerified(""),
    contentApi: new ContentApi(""),
    liveClassApi: new LiveClassApi(""),
    testApi: new TestApi(""),
    tutorApi: new TutorApi(""),
  };
  const initialSessionStore: SessionStore = {
    UserInfo: defaultUserInfo,
    allApiVerified: initApiVerified,
    reloadDashboard: false,
    fullscreenState: false,
    hideNavbar: false,
    hideFooter: false,
    isTabletScreen: false,
    isMobileScreen: false,
    navbarMenu: null,
    userAlerts: null,
    lastAccessedTime: null,
    showAffiliationSelector: false,
    isSessionReady: false,
  };

  return JSON.parse(JSON.stringify(initialSessionStore));
}

export const useSessionStore = defineStore("sessionStore", {
  state: (): SessionStore => getInitialSessionStore(),
  getters: {
    IsLoggedIn(state) {
      return state.UserInfo.AccessToken !== null;
    },
    IsStudent(state) {
      return Object.values(AllStudentRoles).includes(state.UserInfo.UserRole);
    },
    IsMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    },
    IsManagerRole(state) {
      return AllManagerRoles.includes(state.UserInfo.UserRole);
    },
    IsAdminRole(state) {
      return AllAdminRoles.includes(state.UserInfo.UserRole);
    },
  },
  actions: {
    async pingApiServer() {
      const response = await apiUnverified.pingApiServer();
      return response.statusCode;
    },
    async initLoginWithPassword(loginCredentials: LoginWithPassword) {
      const speedlabsStore = useSpeedlabsStore();
      if (speedlabsStore.IsCustomDomain) {
        loginCredentials.domainName = window.location.hostname;
      }
      const response = await apiUnverified.loginWithPassword(loginCredentials);
      await this.processLoginResponse(response);
    },
    async initLoginWithOtp(loginCredentials: LoginWithOtp) {
      const speedlabsStore = useSpeedlabsStore();
      if (speedlabsStore.IsCustomDomain) {
        loginCredentials.domainName = window.location.hostname;
      }
      const response = await apiUnverified.loginWithOtp(loginCredentials);
      await this.processLoginResponse(response);
    },
    async processLoginResponse(response: ApiResponse) {
      if (response.isSuccess) {
        this.UserInfo = response.data;
        this.updateApiObjects();
        await Promise.all([
          this.showHideAffiliationSelector(),
          this.getNavbarMenu(),
          this.getUserAlerts(),
        ]);
        router.push({ name: this.navbarMenu?.DefaultView });
      } else {
        showCustomMessage({
          messageText: response.errorMessage,
          messageType: "error",
          duration: 60,
        });
      }
    },
    async submitB2CRegistration(registerCredentials: RegisterCredentials) {
      const response = await apiUnverified.registerB2CUser(registerCredentials);
      if (response.isSuccess) {
        return { isSuccess: true, message: "Congrats, you have registered successfully." };
      } else {
        return { isSuccess: false, message: response.errorMessage };
      }
    },
    async getNavbarMenu() {
      const response = await this.allApiVerified.apiVerified.getNavbarMenu(
        this.UserInfo.UserRoleId,
        this.UserInfo.AffiliationId,
      );
      if (response.isSuccess) {
        this.navbarMenu = response.data as NavbarMenu;
      } else {
        // TODO: Fix this: navigate to logout?
      }
    },
    async getUserAlerts() {
      const response = await this.allApiVerified.apiVerified.getUserAlerts(
        this.UserInfo.UserRoleId,
      );
      this.userAlerts = response.isSuccess ? (response.data as Array<UserAlert>) : null;
    },
    async showHideAffiliationSelector() {
      if (!this.IsManagerRole) {
        return;
      }
      if (this.IsAdminRole) {
        this.showAffiliationSelector = true;
        return;
      }
      const response = await this.allApiVerified.apiVerified.checkIsParentAffiliation(
        this.UserInfo.AffiliationId,
      );
      this.showAffiliationSelector = response.isSuccess ? response.data : false;
    },
    async sendOtpForLogin(loginId: string | undefined) {
      if (!loginId) {
        showCustomMessage({
          messageText: "Please enter a valid Login Id to login with OTP!",
          messageType: "error",
        });
        return;
      }
      const response = await apiUnverified.sendLoginOtp(loginId);
      if (!(response.isSuccess && response.data.otpId)) {
        showCustomMessage({
          messageText: "There was an error sending an OTP to your Login Id!",
          messageType: "error",
        });
        return;
      }
      return response.data.otpId;
    },
    getOldAppUrl(viewName?: string) {
      if (!this.UserInfo.SLToken) {
        return;
      }
      if (!viewName) {
        viewName = this.UserInfo.UserDashboard;
      }
      const oldAppUrl = `${import.meta.env.VITE_OLD_VUE_APP_URL}/sso?token=${
        this.UserInfo.SLToken
      }&redirectTo=${viewName}`;
      return oldAppUrl;
    },
    async updateSessionLastAccessed() {
      // If lastAccessedTime is less than 1 minute earlier, don't send update request
      const currentTimestamp = new Date().valueOf();
      if (this.lastAccessedTime !== null && currentTimestamp - this.lastAccessedTime < 60 * 1000) {
        return;
      }
      await this.allApiVerified.apiVerified.updateSessionLastAccessed(this.UserInfo.LoginSessionId);
      this.lastAccessedTime = currentTimestamp;
    },
    async checkActiveSessionValidity() {
      if (!this.IsLoggedIn) {
        return;
      }
      const response = await apiUnverified.getActiveLoginSession(this.UserInfo.UserId);
      if (response.isSuccess && response.data !== this.UserInfo.LoginSessionId) {
        showCustomMessage({
          messageText: "You have been logged out as a new login session was detected!",
          messageType: "error",
        });
        await onWebAppLogout(true);
      }
    },
    async refreshAccessToken() {
      if (this.UserInfo.RefreshToken !== null) {
        const response = await apiUnverified.refreshAccessToken(this.UserInfo.RefreshToken);
        if (response.isSuccess) {
          this.UserInfo.AccessToken = response.data;
          this.updateApiObjects();
        } else {
          // If refresh token is not working, log the user out
          await onWebAppLogout(true);
        }
      }
      return this.UserInfo.AccessToken;
    },
    updateApiObjects() {
      const accessToken = this.UserInfo.AccessToken ? this.UserInfo.AccessToken : "";
      this.allApiVerified.apiVerified = new ApiVerified(accessToken);
      this.allApiVerified.contentApi = new ContentApi(accessToken);
      this.allApiVerified.liveClassApi = new LiveClassApi(accessToken);
      this.allApiVerified.testApi = new TestApi(accessToken);
      this.allApiVerified.tutorApi = new TutorApi(accessToken);
      this.isSessionReady = true;
    },
    activateFullscreenMode() {
      this.fullscreenState = true;
    },
    fullscreenStateChanged(isFullscreen: boolean) {
      if (!isFullscreen) {
        this.fullscreenState = false;
      }
    },
    resetFullscreenState() {
      this.fullscreenState = false;
    },
    addWindowResizeListener() {
      const tabletMediaQuery = window.matchMedia("(max-width: 991px)");
      this.isTabletScreen = tabletMediaQuery.matches;
      tabletMediaQuery.addEventListener("change", (e) => {
        this.isTabletScreen = e.matches;
      });
      const mobileMediaQuery = window.matchMedia("(max-width: 575px)");
      this.isMobileScreen = mobileMediaQuery.matches;
      mobileMediaQuery.addEventListener("change", (e) => {
        this.isMobileScreen = e.matches;
      });
    },
    async validateLoggedInSession() {
      if (!this.IsLoggedIn) {
        return true;
      }
      const speedlabsStore = useSpeedlabsStore();
      const { CustomAssets } = storeToRefs(speedlabsStore);
      const isValidAppVersion =
        this.UserInfo.AppVersion !== null &&
        this.UserInfo.AppVersion === speedlabsStore.SpeedlabsConfig.WebVersion;
      const isValidCustomDomain =
        !speedlabsStore.IsCustomDomain ||
        (CustomAssets.value !== undefined &&
          CustomAssets.value.CustomDomain === window.location.hostname);
      if (!(isValidAppVersion && isValidCustomDomain)) {
        await onWebAppLogout(true);
        return false;
      }
      this.updateApiObjects();
      await this.getUserAlerts();
      return true;
    },
  },
  persist: true,
});
