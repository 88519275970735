import { getImageUrl } from "@/methods/utils";
import ApiUnverified from "@/services/apiUnverified";
import { AppConfig, SpeedlabsStore } from "@/types/global";
import { defineStore } from "pinia";

function getInitialSpeedlabsStore(): SpeedlabsStore {
  const defaultConfig: AppConfig = {
    WebVersion: null,
    AndroidVersion: null,
    iOSVersion: null,
    CustomAssets: undefined,
  };
  const initialSpeedlabsStore: SpeedlabsStore = {
    SpeedlabsConfig: defaultConfig,
    IsCustomDomain: false,
  };
  return JSON.parse(JSON.stringify(initialSpeedlabsStore));
}

export const useSpeedlabsStore = defineStore("speedlabsStore", {
  state: (): SpeedlabsStore => getInitialSpeedlabsStore(),
  getters: {
    CustomAssets(state) {
      return state.SpeedlabsConfig.CustomAssets;
    },
    AffiliationLogo(state) {
      return state.SpeedlabsConfig.CustomAssets?.AffiliationLogo;
    },
    DashboardLogo(state) {
      return state.SpeedlabsConfig.CustomAssets?.DashboardLogo;
    },
    AppRefreshRequired(state) {
      return state.SpeedlabsConfig.WebVersion !== import.meta.env.VITE_APP_VERSION;
    },
  },
  actions: {
    async loadSpeedlabsConfig() {
      const response = await new ApiUnverified().getSpeedlabsConfig();
      if (!response.isSuccess) {
        throw new Error("No valid config found for the SpeEdLabs app!");
      }
      this.SpeedlabsConfig = response.data;
      this.IsCustomDomain = window.location.hostname !== import.meta.env.VITE_DEFAULT_HOSTNAME;
      if (this.IsCustomDomain) {
        await this.updateCustomAssets();
      }
    },
    async updateCustomAssets() {
      if (
        this.SpeedlabsConfig.CustomAssets !== undefined &&
        this.SpeedlabsConfig.CustomAssets.CustomDomain === window.location.hostname
      ) {
        return;
      }
      const response = await new ApiUnverified().getAffiliationCustomAssets(
        window.location.hostname,
      );
      const defaultCustomAssets = {
        CustomDomain: null,
        EmailId: null,
        PhoneNumber: null,
        AffiliationLogo: null,
        DashboardLogo: null,
        CarouselImages: [
          getImageUrl("custom-domain-carousel-1.svg"),
          getImageUrl("custom-domain-carousel-2.svg"),
          getImageUrl("custom-domain-carousel-3.svg"),
        ],
      };
      this.SpeedlabsConfig.CustomAssets = response.isSuccess ? response.data : defaultCustomAssets;
    },
  },
  persist: true,
});
