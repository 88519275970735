import { ApiResponse } from "@/types/global";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";

export function handleApiResponse(response: AxiosResponse): ApiResponse {
  return {
    isSuccess: true,
    statusCode: HttpStatusCode.Ok,
    data: response.data,
    errorMessage: undefined,
  };
}

export function handleApiError(error: unknown): ApiResponse {
  let statusCode = HttpStatusCode.InternalServerError;
  const defaultMessage = "Unknown Server Error: An internal server error occurred.";
  let errorMessage = defaultMessage;
  if (error instanceof AxiosError) {
    if (!error.response) {
      return {
        isSuccess: false,
        data: undefined,
        statusCode: 0,
        errorMessage: "Server unavailable: Please try again after some time.",
      };
    }
    statusCode = error.response.status;
    if (statusCode === HttpStatusCode.BadRequest) {
      errorMessage = "Client error: Possible bad request.";
    } else if (statusCode === HttpStatusCode.Forbidden) {
      errorMessage = "Forbidden: Token expired.";
    } else if (statusCode === HttpStatusCode.TooManyRequests) {
      errorMessage = "Rate Limited: Too many requests, please try again later.";
    } else if (error.message === "Network Error") {
      errorMessage = "No internet connection: Please check your network connection.";
    } else {
      errorMessage = error.response.data.detail ? error.response.data.detail : defaultMessage;
    }
  }
  return { isSuccess: false, data: undefined, statusCode: statusCode, errorMessage: errorMessage };
}
