import { defineStore } from "pinia";
import { extend } from "@vue/shared";

const component = extend({});
type VueComponent = InstanceType<typeof component>;
interface ModalProps {
  component: null | VueComponent;
  props?: object;
  closeOnClickout: boolean;
}
interface ModalState {
  modalState: ModalProps;
}

const basicState = { component: null, props: {}, closeOnClickout: false };

export const useModalStore = defineStore("modalState", {
  state: (): ModalState => ({ modalState: basicState }),
  actions: {
    openModal(payload: ModalProps) {
      const { props, component, closeOnClickout } = payload;
      this.modalState = { component, props: props || {}, closeOnClickout };
      document.body.classList.add("prevent-scroll");
    },
    closeModal() {
      this.modalState = basicState;
      document.body.classList.remove("prevent-scroll");
    },
  },
});
