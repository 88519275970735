export enum UserRolesEnum {
  ADMIN = "Admin",
  STUDENT = "Student",
  CENTER_MANAGER = "Center Manager",
  FACULTY = "Faculty",
  CONTENT_HEAD = "Content Head",
  AFFILIATION_HEAD = "Affiliation Head",
  DEMO_STUDENT = "Demo Student",
  TUTOR = "Tutor",
  MODERATOR = "Moderator",
  CONTENT_MANAGER = "Content Manager",
  PARENT = "Parent",
  B2C_INDIVIDUAL_TEACHER = "B2C Individual Teacher",
  SCHOOL_TEACHER = "SchoolTeacher",
  ACADEMIC_EXCELLENCE_MANAGER = "Academic Excellence Manager",
}

export enum UserRoleIdsEnum {
  ADMIN = 1,
  STUDENT = 2,
  CENTER_MANAGER = 3,
  FACULTY = 4,
  CONTENT_HEAD = 5,
  AFFILIATION_HEAD = 7,
  DEMO_STUDENT = 8,
  TUTOR = 11,
  MODERATOR = 12,
  CONTENT_MANAGER = 14,
  PARENT = 18,
  B2C_INDIVIDUAL_TEACHER = 19,
  SCHOOL_TEACHER = 20,
  ACADEMIC_EXCELLENCE_MANAGER = 21,
}

export enum GenderEnum {
  MALE = 1,
  FEMALE = 2,
}

export enum NavbarModeEnum {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum ContentEnum {
  COURSE = "Course",
  CLASS = "Class",
  SUBJECT = "Subject",
  TOPIC = "Topic",
  CHAPTER = "Chapter",
  COURSE_CHAPTER = "CourseChapter",
  KSC_CLUSTER = "KSCCluster",
  KSC = "KSC",
}

export enum QuestionTypeEnum {
  OBJECTIVE = "Objective",
  SUBJECTIVE = "Subjective",
  MULTIPLE_CHOICE = "Multiple Choice",
  NUMERICAL = "Numerical",
  ASSERTION_REASON = "Assertion and Reason",
  COMPREHENSION = "Comprehension",
  MATCH_THE_COLUMN = "MatchTheColumn",
  TRUE_FALSE = "True/False",
}

export enum QuestionTypeIdEnum {
  OBJECTIVE = 1,
  SUBJECTIVE = 2,
  MULTIPLE_CHOICE = 3,
  NUMERICAL = 4,
  ASSERTION_REASON = 5,
  COMPREHENSION = 6,
  MATCH_THE_COLUMN = 7,
  TRUE_FALSE = 8,
}

export enum DifficultyLevelEnum {
  EASY = 1,
  MEDIUM = 2,
  HARD = 3,
}

export enum WeekdaysEnum {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}

export enum PdfViewerTemplateEnum {
  SINGLE = 0,
  DOUBLE = 1,
  NO_CONTROLS = 2,
}

export enum CustomFilterTypeEnum {
  DROPDOWN = "Dropdown",
  MULTI_SELECT = "MultiSelect",
  RADIO_BUTTON = "RadioButton",
  BATCH_TREE_SELECT = "BatchTreeSelect",
  DATE_PICKER = "DatePicker",
  DATE_RANGE_PICKER = "DateRangePicker",
}

export enum ContentNamesEnum {
  CLASS = "Class",
  SUBJECT = "Subject",
  TOPIC = "Topic",
  CHAPTER = "Chapter",
}

export enum QuestionCategoriesEnum {
  ALL = 1,
  EXAMPLE = 2,
  ASSIGNMENT = 3,
  QUIZ = 4,
  TEST = 5,
  RECOMMENDED = 9,
  INSTITUTE = 10,
}

export enum QuestionSelectorSortEnum {
  DIFFICULTY = 1,
  CONCEPTS = 2,
  NEWEST = 3,
}

export enum ActiveInactiveStatusEnum {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum ActivityTypesEnum {
  REFERRAL = 1,
  LOGIN = 2,
  LEARN = 3,
  LIVE_CLASS = 4,
  PRACTICE = 5,
  SELF_TEST = 6,
  INSTITUTE_TEST = 7,
  ASSIGNMENT = 8,
}

export enum ActivityNamesEnum {
  REFERRAL = "Referral",
  LOGIN = "Login",
  LEARN = "Learn",
  LIVE_CLASS = "LiveClass",
  PRACTICE = "Practice",
  SELF_TEST = "SelfTest",
  INSTITUTE_TEST = "InstituteTest",
  ASSIGNMENT = "Assignment",
  CONCEPTS = "Concepts",
}

export enum RecommendationTypeEnum {
  PRACTICE = "Practice",
  ASSIGNMENTS = "Assignments",
  TESTS = "Tests",
  VIDEOS = "Videos",
}

export enum DatePeriodOptionsEnum {
  "1 Week" = 7,
  "2 Weeks" = 14,
  "1 Month" = 30,
  "3 Month" = 90,
}

export enum BugCategoriesEnum {
  "Missing Content" = 1,
  "Incorrect Content" = 2,
  "Feature Not Working" = 3,
  "Other" = 4,
}

export enum QuestionReviewEnum {
  APPROVED = 1,
  REJECTED = 2,
  EDIT_NEEDED = 3,
}

export enum QuestionDisplayTypeEnum {
  HTML = "renderedHtml",
  DESKTOP = "desktopImage",
  MOBILE = "mobileImage",
}

export enum QuestionPdfImageTypeEnum {
  PAGE = "page",
  QUESTION = "question",
  SOLUTION = "solution",
}

export enum QuestionPdfStatusEnum {
  PDF_UPLOADED = 1,
  PDF_PROCESSED = 2,
  PDF_FAILED = 3,
  PDF_VERIFIED = 4,
  IMAGES_EXTRACTED = 5,
  EXTRACTION_FAILED = 6,
  IMAGES_SAVED = 7,
  QUESTION_BANK = 8,
  PDF_ARCHIVED = 9,
}

export enum QuestionBankStatusEnum {
  NEW_QUESTION = 1,
  TYPE_TAGGED = 2,
  PROCESSING_PENDING = 3,
  PROCESSING_FAILED = 4,
  LATEX_CONVERTED = 5,
  CONTENT_TAGGED = 6,
  LATEX_APPROVED = 7,
  REJECTED = 8,
  EDIT_NEEDED = 9,
  FINAL_APPROVED = 10,
}

export enum QuestionBankReviewEnum {
  LATEX_APPROVED = 7,
  REJECTED = 8,
  EDIT_NEEDED = 9,
  FINAL_APPROVED = 10,
}

export enum PracticeSessionCardEnum {
  PENDING = "Pending",
  COMPLETED = "Completed",
}
