<template>
  <div class="form-group">
    <el-upload
      v-model:file-list="fileList"
      :accept="fileType"
      :auto-upload="false"
      :limit="uploadLimit"
      :drag="!onlyButton"
      :show-file-list="!onlyButton"
      :on-remove="onFileRemoved"
      :on-change="onUploadFileChanged"
      class="fs-xxs color-info"
    >
      <div v-if="onlyButton">
        <div class="button action small" style="margin-bottom: -5px">{{ buttonText }}</div>
      </div>
      <div v-else>
        <i :class="`bi bi-${fileIcon} fs-xl tighter`"></i>
        <div class="f-italic">Drop a file here or click to upload</div>
      </div>
      <template #tip>
        <div class="el-upload__tip">{{ uploadTip }}</div>
      </template>
    </el-upload>
  </div>
</template>
<script lang="ts">
import { UploadProps, UploadUserFile } from "element-plus";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CustomFileUpload",
  props: {
    inputId: { type: String, required: true },
    fileIcon: { type: String, default: "file-earmark-text" },
    fileType: { type: String, default: "text" },
    uploadTip: { type: String, default: "*file with size less than 5MB" },
    uploadLimit: { type: Number, default: 1 },
    onlyButton: { type: Boolean, default: false },
    buttonText: { type: String, default: "Upload File" },
  },
  setup(props, { emit }) {
    const fileList = ref<Array<UploadUserFile>>();
    const onUploadFileChanged: UploadProps["onChange"] = (uploadFile, uploadFiles) => {
      fileList.value = uploadFiles.slice(0, props.uploadLimit);
      emit("onChange", fileList.value);
    };
    function onFileRemoved() {
      emit("onRemove", fileList.value);
    }
    return { fileList, onUploadFileChanged, onFileRemoved };
  },
});
</script>
