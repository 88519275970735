<template>
  <div class="flexcol xcenter p-5">
    <div class="loading-image-container">
      <img :src="getImageUrl(loadingImage)" alt="" class="loading-image" />
    </div>
    <div class="progress-bar">
      <el-progress :indeterminate="true" :percentage="50" :show-text="false" />
    </div>
    <div class="ff-default color-info my-2">{{ loadingText }}...</div>
  </div>
</template>
<script lang="ts">
import { getImageUrl } from "@/methods/utils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheLoadingPage",
  props: {
    loadingImage: { type: String, required: true },
    loadingText: { type: String, required: true },
  },

  setup() {
    return { getImageUrl };
  },
});
</script>

<style lang="scss" scoped>
.loading-image-container {
  min-height: 320px;
  & .loading-image {
    width: 480px;
    @include media("<=s") {
      width: 90%;
    }
  }
}

.progress-bar {
  width: 120px;
}
</style>
