import { AllTutorRoles, AllAffiliationRoles } from "@/constants/userRoles";

export default [
  {
    path: "/tutoring/admin-dashboard",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringAdminDashboard' */ "@/views/tutoring/TutoringAdminDashboard.vue"
      ),
    name: "TutoringAdminDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/tutoring/book-paid-classes",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringBookPaidClasses' */ "@/views/tutoring/TutoringBookPaidClasses.vue"
      ),
    name: "TutoringBookPaidClasses",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/tutoring/tutor-dashboard",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringTutorDashboard' */ "@/views/tutoring/TutoringTutorDashboard.vue"
      ),
    name: "TutoringTutorDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutorRoles,
    },
  },
  {
    path: "/tutoring/tutor-lesson-plans",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringTutorLessonPlans' */ "@/views/tutoring/TutoringTutorLessonPlans.vue"
      ),
    name: "TutoringTutorLessonPlans",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutorRoles,
    },
  },
  {
    path: "/tutoring/tutor-calendar",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringTutorCalendar' */ "@/views/tutoring/TutoringTutorCalendar.vue"
      ),
    name: "TutoringTutorCalendar",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutorRoles,
    },
  },
];
