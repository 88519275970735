<template>
  <div class="flexrow xend ycenter gap-2">
    <!-- <div v-if="showReportBug" class="cursor-pointer" @click="onReportBugClicked">
      <i class="bi bi-bug fs-m lh-none color-danger"></i>
    </div> -->
    <TheCloseButton closeButtonTarget="previous" />
    <TheCloseButton closeButtonTarget="dashboard" />
  </div>
</template>
<script lang="ts">
import ModalReportBug from "@/components/modals/ModalReportBug.vue";
import TheCloseButton from "@/components/TheCloseButton.vue";
import { useModalStore } from "@/stores/modalStore";
import { defineComponent, markRaw } from "vue";
export default defineComponent({
  name: "ThePreviousCloseButtons",
  components: { TheCloseButton },
  props: { showReportBug: { type: Boolean, default: false } },
  setup() {
    const modalStore = useModalStore();
    const { openModal } = modalStore;
    function onReportBugClicked() {
      openModal({
        component: markRaw(ModalReportBug),
        closeOnClickout: false,
      });
    }
    return { onReportBugClicked };
  },
});
</script>
