import { AllAEMRoles } from "@/constants/userRoles";
import { AllContentRoles } from "@/constants/userRoles";
export default [
  {
    path: "/aem/dashboard",
    component: () => import(/* webpackChunkName: 'AEMDashboard' */ "@/views/aem/AEMDashboard.vue"),
    name: "AEMDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAEMRoles,
    },
  },
  {
    path: "/aem/manage-omr-uploads",
    component: () =>
      import(/* webpackChunkName: 'ManageOmrUploads' */ "@/views/aem/ManageOmrUploads.vue"),
    name: "ManageOmrUploads",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/aem/finalize-uploaded-omrs/:instituteTestId/:batchId/:omrUploadId",
    component: () =>
      import(/* webpackChunkName: 'FinalizeUploadedOmrs' */ "@/views/aem/FinalizeUploadedOmrs.vue"),
    name: "FinalizeUploadedOmrs",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
];
