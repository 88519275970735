<template>
  <div class="form-group">
    <div
      class="flexrow ycenter gap-2 fw-bold"
      :class="isTabletScreen || isSmall ? 'fs-xxs' : 'fs-xs'"
    >
      <span v-if="inactiveLabel" :class="`color-${inactiveLabelColor}`">
        {{ inactiveLabel }}
      </span>
      <el-switch
        v-model="inputValue"
        :inactive-value="inactiveValue"
        :active-value="activeValue"
        @change="onChange"
        :size="isTabletScreen || isSmall ? 'small' : 'default'"
        :disabled="isDisabled"
      />
      <span v-if="activeLabel" :class="`color-${activeLabelColor}`">
        {{ activeLabel }}
      </span>
    </div>
    <div v-if="isFormField">
      <Field v-model="inputValue" :id="inputId" :name="inputId" hidden />
      <ErrorMessage :name="inputId" class="validation-message" />
    </div>
  </div>
</template>

<script lang="ts">
import { useSessionStore } from "@/stores/sessionStore";
import { storeToRefs } from "pinia";
import { ErrorMessage, Field } from "vee-validate";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CustomSwitch",
  components: { ErrorMessage, Field },
  props: {
    inputId: { type: String, required: true },
    inactiveValue: { type: [Number, Boolean], required: true },
    activeValue: { type: [Number, Boolean], required: true },
    initialValue: { type: [Number, Boolean], default: false },
    inactiveLabel: { type: String },
    inactiveLabelColor: { type: String, default: "danger" },
    activeLabel: { type: String },
    activeLabelColor: { type: String, default: "info" },
    isFormField: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const sessionStore = useSessionStore();
    const { isTabletScreen } = storeToRefs(sessionStore);

    const inputValue = ref(props.initialValue);
    function onChange() {
      emit("onChange", inputValue.value);
    }
    return { inputValue, isTabletScreen, onChange };
  },
});
</script>
<style lang="scss">
.el-switch {
  --el-switch-on-color: #{$color-info};
  --el-switch-off-color: #{$color-danger};
}
</style>
