<template>
  <div>
    <label :for="inputId" :class="`input-label ${labelClass}`">
      <span>{{ inputLabel }}</span>
      <span v-if="isMandatory" class="text-danger ms-1">*</span>
    </label>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomLabel",
  props: {
    inputLabel: { type: String, required: true },
    inputId: { type: String, required: true },
    labelClass: { type: String, default: "" },
    isMandatory: { type: Boolean, default: false },
  },
});
</script>
