<template>
  <div class="form-group">
    <CustomLabel
      v-if="inputLabel"
      :inputLabel="inputLabel"
      :inputId="inputId"
      :isMandatory="isMandatory"
    />
    <el-input
      v-model="inputValue"
      :type="inputType"
      :placeholder="inputPlaceholder"
      @input="onChange"
      @blur="onBlur"
      :disabled="isDisabled"
      :size="isTabletScreen || isSmall ? 'small' : 'default'"
      :clearable="isClearable"
      :rows="numberOfRows"
    />
    <div v-if="isFormField">
      <Field v-model="inputValue" :id="inputId" :name="inputId" :type="inputType" hidden />
      <ErrorMessage :name="inputId" class="validation-message" />
    </div>
  </div>
</template>
<script lang="ts">
import CustomLabel from "@/components/input/CustomLabel.vue";
import { useSessionStore } from "@/stores/sessionStore";
import { storeToRefs } from "pinia";
import { ErrorMessage, Field } from "vee-validate";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CustomInput",
  props: {
    inputId: { type: String, required: true },
    initialValue: { type: [String, Number], required: false },
    inputType: { type: String, default: "text" },
    inputPlaceholder: { type: String, required: false },
    inputLabel: { type: String, required: false },
    isMandatory: { type: Boolean, default: false },
    isFormField: { type: Boolean, default: false },
    isClearable: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
    numberOfRows: { type: Number, default: 1 },
  },
  components: { CustomLabel, Field, ErrorMessage },
  setup(props, { emit }) {
    const inputValue = ref(props.initialValue);
    const sessionStore = useSessionStore();
    const { isTabletScreen } = storeToRefs(sessionStore);
    function onChange() {
      emit("onChange", inputValue.value);
    }
    function onBlur() {
      emit("onBlur", inputValue.value);
    }
    return { inputValue, isTabletScreen, onChange, onBlur };
  },
});
</script>
