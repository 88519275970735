<template>
  <div>
    <div :class="`button ${color}-reverse p-0`" @click="closeModal">
      <i class="bi bi-x fs-l"></i>
    </div>
  </div>
</template>
<script lang="ts">
import { useModalStore } from "@/stores/modalStore";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalCloseButton",
  props: { color: { type: String, required: true } },
  setup() {
    const modalStore = useModalStore();
    const { closeModal } = modalStore;
    return { closeModal };
  },
});
</script>
