<template>
  <div id="WebAppNavbar" class="navbar-container" v-if="!$route.meta.hideNavbar">
    <div class="navbar-horizontal">
      <div class="flexrow xbetween ycenter">
        <div class="flexcol xend">
          <img
            class="sl-logo-navbar ms-4 cursor-pointer"
            :src="navbarLogo"
            alt="SpeEdLabs"
            @click="gotoDashboard"
          />
          <div v-if="!isProductionEnv" class="fs-xxxs color-white">{{ appVersionNumbers }}</div>
        </div>
        <TheNavbarMenu
          v-if="navbarMenu"
          :navbarMenuItems="navbarMenu.MenuItems"
          :navbarMode="NavbarModeEnum.HORIZONTAL"
        />
      </div>
    </div>
    <div class="navbar-vertical py-1">
      <div class="flexrow xbetween ycenter p-2 ms-2">
        <div class="flexrow gap-3 ycenter">
          <i
            class="bi bi-list fs-l tightest color-white cursor-pointer"
            @click="toggleNavbarDrawer"
          ></i>
          <img
            class="sl-logo-navbar cursor-pointer"
            :src="navbarLogo"
            alt="SpeEdLabs"
            @click="gotoDashboard"
          />
        </div>
        <div
          class="bi bi-box-arrow-right fs-l tightest color-white ms-4 me-2 cursor-pointer"
          @click="onWebAppLogout(false)"
        ></div>
      </div>
      <el-drawer
        v-model="navbarDrawerOpen"
        :with-header="false"
        :destroy-on-close="true"
        direction="ltr"
        :size="280"
      >
        <div class="flexcol xstart gap-1">
          <img class="sl-logo-navbar" :src="navbarLogo" alt="SpeEdLabs" />
          <div v-if="!isProductionEnv" class="fs-xxxs color-white">{{ appVersionNumbers }}</div>
        </div>
        <TheNavbarMenu
          v-if="navbarMenu"
          :navbarMenuItems="navbarMenu.MenuItems"
          :navbarMode="NavbarModeEnum.VERTICAL"
        />
      </el-drawer>
    </div>
    <TheAffiliationSelector
      v-if="showAffiliationSelector && currentRoute.meta.showAffiliationSelector"
    />
  </div>
</template>

<script lang="ts">
import TheAffiliationSelector from "@/components/affiliation/TheAffiliationSelector.vue";
import TheNavbarMenu from "@/components/TheNavbarMenu.vue";
import { AllStudentRoles } from "@/constants/userRoles";
import { NavbarModeEnum } from "@/enums/global";
import { getImageUrl, gotoDashboard, onWebAppLogout } from "@/methods/utils";
import { useParentAffiliationStore } from "@/stores/parentAffiliationStore";
import { useSessionStore } from "@/stores/sessionStore";
import { useSpeedlabsStore } from "@/stores/speedlabsStore";
import { storeToRefs } from "pinia";
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "TheAppNavbar",
  components: { TheNavbarMenu, TheAffiliationSelector },
  setup() {
    const currentRoute = useRoute();
    const speedlabsStore = useSpeedlabsStore();
    const { DashboardLogo } = storeToRefs(speedlabsStore);
    const sessionStore = useSessionStore();
    const { showAffiliationSelector, navbarMenu, UserInfo } = storeToRefs(sessionStore);
    const navbarDrawerOpen = ref(false);
    const navbarLogo = computed(() => {
      return DashboardLogo.value ? DashboardLogo.value : getImageUrl("sl-logo-navbar.svg");
    });
    const isProductionEnv = computed(() => {
      return import.meta.env.MODE === "production";
    });
    const appVersionNumbers = computed(() => {
      return `${sessionStore.UserInfo.AppVersion} / ${
        speedlabsStore.SpeedlabsConfig.WebVersion
      } / ${import.meta.env.VITE_APP_VERSION}`;
    });
    const parentAffiliationStore = useParentAffiliationStore();
    const { initializeAffiliationSelector } = parentAffiliationStore;
    function toggleNavbarDrawer() {
      navbarDrawerOpen.value = !navbarDrawerOpen.value;
    }
    onBeforeMount(async () => {
      if (!AllStudentRoles.includes(UserInfo.value.UserRole)) {
        await initializeAffiliationSelector();
      }
    });

    return {
      isProductionEnv,
      appVersionNumbers,
      NavbarModeEnum,
      navbarLogo,
      navbarMenu,
      navbarDrawerOpen,
      currentRoute,
      showAffiliationSelector,
      toggleNavbarDrawer,
      gotoDashboard,
      onWebAppLogout,
    };
  },
});
</script>

<style lang="scss" scoped>
.navbar-container {
  background-color: $color-primary;
  .navbar-horizontal {
    @include media("<=m") {
      display: none;
    }
  }
  .navbar-vertical {
    background-color: $color-primary;
    @include media(">m") {
      display: none;
    }
  }

  .partner-logo-navbar,
  .sl-logo-navbar {
    height: 32px;
    @include media("<=l") {
      height: 24px;
    }
    @include media("<=s") {
      height: 20px;
    }
  }
}
</style>

<style lang="scss">
.navbar-vertical .el-drawer {
  --el-drawer-bg-color: #{$color-primary};
}
</style>
