<template>
  <div class="form-group">
    <CustomLabel
      v-if="inputLabel"
      :inputLabel="inputLabel"
      :inputId="inputId"
      :isMandatory="isMandatory"
    />
    <el-select
      v-model="inputValue"
      :placeholder="inputPlaceholder ? inputPlaceholder : `Select ${inputLabel}`"
      @change="onChange"
      @blur="onBlur"
      :multiple="isMultiSelect"
      :filterable="isFilterable"
      :clearable="isClearable"
      :disabled="isDisabled"
      collapse-tags
      :size="isTabletScreen ? 'small' : 'default'"
    >
      <el-option
        v-for="dropdownItem in dropdownItems"
        :value="dropdownItem.Id"
        :label="dropdownItem.Name"
      />
    </el-select>
    <div v-if="isFormField">
      <Field v-model="inputValue" :id="inputId" :name="inputId" hidden />
      <ErrorMessage :name="inputId" class="validation-message" />
    </div>
  </div>
</template>

<script lang="ts">
import CustomLabel from "@/components/input/CustomLabel.vue";
import { useSessionStore } from "@/stores/sessionStore";
import { IdNamePair } from "@/types/global";
import { storeToRefs } from "pinia";
import { ErrorMessage, Field } from "vee-validate";
import { PropType, defineComponent, ref } from "vue";

export default defineComponent({
  name: "CustomDropdown",
  props: {
    inputId: { type: String, required: true },
    inputLabel: { type: String, required: false },
    dropdownItems: { type: Array as PropType<Array<IdNamePair>> },
    initialValue: { type: [Number, Array<number>], default: null },
    inputPlaceholder: { type: String },
    isMultiSelect: { type: Boolean, default: false },
    isMandatory: { type: Boolean, default: false },
    isFormField: { type: Boolean, default: false },
    isClearable: { type: Boolean, default: false },
    isFilterable: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
  },
  components: { CustomLabel, ErrorMessage, Field },
  setup(props, { emit }) {
    const sessionStore = useSessionStore();
    const { isTabletScreen } = storeToRefs(sessionStore);
    const inputValue = ref(props.initialValue);
    function onChange() {
      emit("onChange", inputValue.value);
    }
    function onBlur() {
      emit("onBlur", inputValue.value);
    }
    return { inputValue, isTabletScreen, onChange, onBlur };
  },
});
</script>
