<template>
  <div class="form-group">
    <div>
      <CustomLabel
        v-if="inputLabel"
        :inputLabel="inputLabel"
        :inputId="inputId"
        :isMandatory="isMandatory"
      />
      <el-tree-select
        v-model="inputValue"
        :data="allTreeNodes"
        :props="treeProps"
        node-key="Id"
        :placeholder="inputPlaceholder ? inputPlaceholder : `Select a ${inputLabel}`"
        :multiple="isMultiSelect"
        :filterable="isFilterable"
        :clearable="isClearable"
        :multiple-limit="multiSelectLimit"
        :accordion="true"
        :filter-node-method="treeFilterMethod"
        collapse-tags
        collapse-tags-tooltip
        @change="onChange"
        @blur="onBlur"
        style="width: 100%"
        :size="isTabletScreen || isSmall ? 'small' : 'default'"
        :disabled="isDisabled"
      />
    </div>
    <div v-if="isFormField">
      <Field v-model="inputValue" :id="inputId" :name="inputId" hidden />
      <ErrorMessage :name="inputId" class="validation-message" />
    </div>
  </div>
</template>

<script lang="ts">
import CustomLabel from "@/components/input/CustomLabel.vue";
import { addParentMappingToTreeData } from "@/methods/utils";
import { useSessionStore } from "@/stores/sessionStore";
import { CustomTreeNode, IdNameChildren } from "@/types/global";
import { storeToRefs } from "pinia";
import { ErrorMessage, Field } from "vee-validate";
import { PropType, defineComponent, onUpdated, ref } from "vue";

export default defineComponent({
  name: "CustomTreeSelect",
  props: {
    inputId: { type: String, required: true },
    inputLabel: { type: String, required: false },
    treeData: { type: Array as PropType<Array<IdNameChildren>> },
    initialValue: { type: [Number, Array<number>], default: null },
    isMultiSelect: { type: Boolean, default: true },
    isFilterable: { type: Boolean, default: true },
    isClearable: { type: Boolean, default: true },
    multiSelectLimit: { type: Number, default: 99 },
    inputPlaceholder: { type: String },
    isMandatory: { type: Boolean, default: false },
    isFormField: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
  },
  components: { CustomLabel, ErrorMessage, Field },

  setup(props, { emit }) {
    const sessionStore = useSessionStore();
    const { isTabletScreen } = storeToRefs(sessionStore);
    const treeProps = { label: "Name", children: "Children" };
    const intialValue = props.initialValue === null ? [] : props.initialValue;
    const inputValue = ref<number | Array<number> | null>(intialValue);
    const allTreeNodes = ref<Array<CustomTreeNode> | undefined>();

    onUpdated(() => {
      if (!props.treeData) {
        return;
      }
      allTreeNodes.value = props.treeData as Array<CustomTreeNode>;
      addParentMappingToTreeData(allTreeNodes.value, null);
    });

    function treeFilterMethod(value: string, data: CustomTreeNode) {
      const searchText = data.SearchText ? data.SearchText : data.Name;
      return searchText.toLowerCase().includes(value.toLowerCase());
    }

    function onChange() {
      if (typeof inputValue.value !== "number" && !Array.isArray(inputValue.value)) {
        inputValue.value = props.isMultiSelect ? [] : null;
      }
      emit("onChange", inputValue.value);
    }
    function onBlur() {
      emit("onBlur", inputValue.value);
    }
    return {
      treeProps,
      allTreeNodes,
      inputValue,
      isTabletScreen,
      treeFilterMethod,
      onChange,
      onBlur,
    };
  },
});
</script>
