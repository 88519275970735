<template>
  <div>
    <CustomLabel
      v-if="inputLabel"
      :inputLabel="inputLabel"
      :inputId="inputId"
      :isMandatory="isMandatory"
    />
    <CustomInput
      :inputId="inputId"
      inputType="textarea"
      :initialValue="inputValue"
      :numberOfRows="numberOfRows"
      :maxlength="maxLength"
      :isFormField="isFormField"
      :isDisabled="isDisabled"
      :size="isTabletScreen ? 'small' : 'default'"
      @onChange="onChange"
    />
    <div class="fs-xxs color-danger my-1" v-if="isMaxLengthExceeded">
      *Exceeded the max length of {{ maxLength }}
    </div>
  </div>
</template>
<script lang="ts">
import CustomInput from "@/components/input/CustomInput.vue";
import CustomLabel from "@/components/input/CustomLabel.vue";
import { useSessionStore } from "@/stores/sessionStore";
import { storeToRefs } from "pinia";
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field } from "vee-validate";

export default defineComponent({
  name: "CustomTextArea",
  components: { CustomInput, CustomLabel, Field, ErrorMessage },
  props: {
    inputId: { type: String, required: true },
    initialValue: { type: [String, Number], required: false },
    inputLabel: { type: String },
    numberOfRows: { type: Number, default: 2 },
    placeholder: { type: String, default: "" },
    isMandatory: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    isFormField: { type: Boolean, default: false },
    maxLength: { type: Number, default: null },
  },
  setup(props, { emit }) {
    const sessionStore = useSessionStore();
    const { isTabletScreen } = storeToRefs(sessionStore);
    const inputValue = ref(props.initialValue);
    const isMaxLengthExceeded = ref(
      inputValue.value &&
        props.maxLength !== null &&
        inputValue.value.toString().length > props.maxLength,
    );

    function onChange(newValue: string) {
      inputValue.value = newValue;
      if (newValue && props.maxLength !== null) {
        isMaxLengthExceeded.value = newValue.length > props.maxLength;
        return;
      }
      emit("onChange", inputValue.value);
    }
    return { inputValue, isTabletScreen, isMaxLengthExceeded, onChange };
  },
});
</script>
