<template>
  <template>
    <Teleport to="body">
      <Transition name="modal-fade">
        <div
          class="modal-wrapper ff-default"
          v-on:click.self="closeOnClick"
          v-if="modalState.component"
        >
          <component :is="modalState.component" v-bind="modalState.props" />
        </div>
      </Transition>
    </Teleport>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useModalStore } from "@/stores/modalStore";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "TheModal",
  setup() {
    const modalStore = useModalStore();
    const { modalState } = storeToRefs(modalStore);
    const { closeModal } = modalStore;
    function closeOnClick() {
      if (modalState.value.closeOnClickout) {
        closeModal();
      }
    }
    return { modalState, closeOnClick };
  },
});
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  display: grid;
  place-items: center;
  left: 0;
  top: 0;
  z-index: 998;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: 0.3s ease all;
}
</style>
