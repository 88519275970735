import { AddEditUserRoleIds, AllAdminRoles } from "@/constants/userRoles";
import { DashboardTabsEnum } from "@/enums/affiliationReport";
import { ActiveInactiveStatusEnum, UserRoleIdsEnum } from "@/enums/global";
import {
  deepCopyObject,
  getIdNamePairsFromEnum,
  getUniqueObjectsByKey,
  showCustomMessage,
} from "@/methods/utils";
import ApiVerified from "@/services/apiVerified";
import { useSessionStore } from "@/stores/sessionStore";
import { AffiliationDisplay } from "@/types/admin";
import { AffiliationCourseBatch, ParentAffiliationStore } from "@/types/affiliation";
import { IdNamePair } from "@/types/global";
import { defineStore, storeToRefs } from "pinia";

function getInitialParentAffiliationStore(): ParentAffiliationStore {
  const initialParentAffiliationStore: ParentAffiliationStore = {
    SelectedAffiliation: null,
    IsParentAffiliation: false,
    SelectAffiliationsByName: false,
    AffiliationStatistics: {},
    AffiliationTypeOptions: null,
    AffiliationOptions: null,
    AffiliationCourseBatchTree: null,
    DetailedBatches: {},
    BatchSubjects: {},
    AffiliationCourses: null,
    CurrentAffiliationTypeId: null,
    CurrentAffiliationStatus: ActiveInactiveStatusEnum.ACTIVE,
    CurrentAffiliationSearchName: null,
    ReloadAffiliationSelectorKey: 0,
    ReloadAffiliationsKey: 0,
    DashboardActiveTab: DashboardTabsEnum.REPORTS,
  };
  return JSON.parse(JSON.stringify(initialParentAffiliationStore));
}
export const useParentAffiliationStore = defineStore("parentAffiliationStore", {
  state: (): ParentAffiliationStore => getInitialParentAffiliationStore(),
  getters: {
    IsValidAffiliationSearchName(): boolean {
      return (
        this.CurrentAffiliationSearchName !== null && this.CurrentAffiliationSearchName.length >= 3
      );
    },
  },
  actions: {
    async initializeAffiliationSelector() {
      const sessionStore = useSessionStore();
      const { UserInfo, IsAdminRole } = storeToRefs(sessionStore);
      if (IsAdminRole.value) {
        this.AffiliationTypeOptions = await this.getAffliationTypes();
      } else {
        await Promise.all([this.getChildAffiliations(), this.getAffiliationCourseBatchTree(null)]);
        if (this.AffiliationOptions !== null && this.AffiliationOptions.length > 0) {
          this.onAffiliationChange(this.AffiliationOptions[0].Id);
        }
      }
    },
    async getChildAffiliations() {
      if (this.AffiliationOptions === null) {
        const sessionStore = useSessionStore();
        const { allApiVerified, UserInfo } = storeToRefs(sessionStore);
        const response = await allApiVerified.value.apiVerified.getChildAffiliations(
          UserInfo.value.AffiliationId,
        );
        if (response.isSuccess) {
          this.AffiliationOptions = response.data;
          this.IsParentAffiliation =
            this.AffiliationOptions !== null && this.AffiliationOptions.length > 1;
        } else {
          showCustomMessage({
            messageText: "No valid/active affiliations found.",
            messageType: "error",
          });
          this.AffiliationOptions = null;
        }
      }
    },
    async getAffliationTypes() {
      const sessionStore = useSessionStore();
      const { UserInfo } = storeToRefs(sessionStore);
      if (UserInfo.value.AccessToken !== null) {
        const response = await new ApiVerified(UserInfo.value.AccessToken).getAffliationTypes();
        if (response.isSuccess) {
          return response.data;
        } else {
          showCustomMessage({
            messageText: "An error occured getting list of affiliation types.",
            messageType: "error",
          });
          return [];
        }
      }
    },
    async getAffiliationStatistics(affiliationId: number) {
      if (this.AffiliationStatistics[affiliationId] === undefined) {
        const sessionStore = useSessionStore();
        const { allApiVerified } = storeToRefs(sessionStore);
        const response = await allApiVerified.value.apiVerified.getAffiliationStatistics(
          affiliationId,
        );
        this.AffiliationStatistics[affiliationId] = response.isSuccess ? response.data : null;
      }
      return this.AffiliationStatistics[affiliationId];
    },
    async getCoursesForAffiliation(affiliationId: number) {
      if (this.AffiliationCourses === null) {
        const sessionStore = useSessionStore();
        const { allApiVerified } = storeToRefs(sessionStore);
        const response = await allApiVerified.value.contentApi.getCoursesForAffiliation(
          affiliationId,
        );
        if (response.isSuccess) {
          this.AffiliationCourses = response.data;
        } else {
          showCustomMessage({ messageText: "No active courses found.", messageType: "warning" });
          this.AffiliationCourses = [];
        }
      }
      return this.AffiliationCourses ? this.AffiliationCourses : [];
    },
    async getAffiliationCourseBatchTree(affiliationId: number | null) {
      if (this.AffiliationCourseBatchTree !== null) {
        if (affiliationId === null) {
          return this.AffiliationCourseBatchTree;
        }
        const matchingAffiliation = this.AffiliationCourseBatchTree.find(
          (affiliation) => affiliation.Id === affiliationId,
        );
        if (matchingAffiliation !== undefined) {
          return this.AffiliationCourseBatchTree;
        }
      }
      const sessionStore = useSessionStore();
      const { allApiVerified, UserInfo } = storeToRefs(sessionStore);
      const response = await allApiVerified.value.apiVerified.getCourseBatchTreeForAffiliation(
        affiliationId === null ? UserInfo.value.AffiliationId : affiliationId,
      );
      if (response.isSuccess) {
        if (this.AffiliationCourseBatchTree === null) {
          this.AffiliationCourseBatchTree = response.data;
        } else {
          const allAffiliations = this.AffiliationCourseBatchTree.concat(response.data);
          this.AffiliationCourseBatchTree = getUniqueObjectsByKey(
            allAffiliations,
            "Id",
          ) as Array<AffiliationCourseBatch>;
        }
      } else {
        showCustomMessage({
          messageText: "No active batches found for affiliation.",
          messageType: "warning",
        });
      }
      return this.AffiliationCourseBatchTree;
    },
    async getCourseBatchTree(affiliationId: number) {
      const affiliationCourseBatchTree = await this.getAffiliationCourseBatchTree(affiliationId);
      if (affiliationCourseBatchTree === null) {
        return null;
      }
      const courseBatchTree = deepCopyObject(
        affiliationCourseBatchTree,
      ) as Array<AffiliationCourseBatch>;
      const matchingAffiliation = courseBatchTree.find((item) => item.Id === affiliationId);
      if (matchingAffiliation === undefined) {
        return null;
      }
      return matchingAffiliation.Courses;
    },
    async getBatchesForAffiliationCourse(affiliationId: number | null, courseId: number | null) {
      const affiliationCourseBatchTree = await this.getAffiliationCourseBatchTree(affiliationId);
      if (affiliationCourseBatchTree === null) {
        return null;
      }
      const allBatches: Array<IdNamePair> = [];
      const isMultiAffiliation = affiliationId === null && affiliationCourseBatchTree.length > 1;
      for (const affiliation of affiliationCourseBatchTree) {
        if (affiliationId !== null && affiliationId !== affiliation.Id) {
          continue;
        }
        for (const course of affiliation.Courses) {
          if (courseId !== null && courseId !== course.Id) {
            continue;
          }
          for (const batch of course.Batches) {
            const batchDisplayName = isMultiAffiliation
              ? `${affiliation.Name} - ${batch.Name}`
              : batch.Name;
            allBatches.push({ Id: batch.Id, Name: batchDisplayName });
          }
        }
      }
      return allBatches;
    },
    async getDetailedBatchesForAffiliation(affiliationId: number, forceReload: boolean) {
      if (forceReload) {
        this.DetailedBatches[affiliationId] = null;
      }
      if (!this.DetailedBatches[affiliationId]) {
        const sessionStore = useSessionStore();
        const { allApiVerified } = storeToRefs(sessionStore);
        const response = await allApiVerified.value.apiVerified.getDetailedAffiliationBatches(
          affiliationId,
        );
        if (response.isSuccess) {
          this.DetailedBatches[affiliationId] = response.data;
        } else {
          showCustomMessage({ messageText: response.errorMessage, messageType: "error" });
          this.DetailedBatches[affiliationId] = [];
        }
      }
      return this.DetailedBatches[affiliationId];
    },
    async getSubjectsForBatch(batchId: number, allSubjectsTitle: string | null) {
      if (this.BatchSubjects[batchId] === undefined) {
        const sessionStore = useSessionStore();
        const { allApiVerified } = storeToRefs(sessionStore);
        const response = await allApiVerified.value.contentApi.getSubjectsForBatch(batchId, true);
        this.BatchSubjects[batchId] = response.isSuccess ? response.data : [];
      }
      let batchSubjects = this.BatchSubjects[batchId];
      if (allSubjectsTitle !== null) {
        const allSubjectsOption = { Id: 0, Name: allSubjectsTitle } as IdNamePair;
        batchSubjects = [allSubjectsOption].concat(this.BatchSubjects[batchId]);
      }
      return batchSubjects;
    },
    async loadAffiliationsData(detailed: boolean) {
      if (!(this.CurrentAffiliationTypeId || this.IsValidAffiliationSearchName)) {
        return;
      }
      const sessionStore = useSessionStore();
      const { allApiVerified } = storeToRefs(sessionStore);
      let response;
      if (this.CurrentAffiliationTypeId) {
        const onlyActive = this.CurrentAffiliationStatus === ActiveInactiveStatusEnum.ACTIVE;
        response = detailed
          ? await allApiVerified.value.apiVerified.getDetailedAffiliationsByType(
              this.CurrentAffiliationTypeId,
              onlyActive,
            )
          : await allApiVerified.value.apiVerified.getAffiliationsByType(
              this.CurrentAffiliationTypeId,
              onlyActive,
            );
      } else if (this.CurrentAffiliationSearchName) {
        response = detailed
          ? await allApiVerified.value.apiVerified.getDetailedAffiliationsByName(
              this.CurrentAffiliationSearchName,
            )
          : await allApiVerified.value.apiVerified.getAffiliationsByName(
              this.CurrentAffiliationSearchName,
            );
      }
      this.ReloadAffiliationsKey += 1;
      if (response) {
        if (response.isSuccess) {
          return response.data;
        } else {
          if (detailed) {
            showCustomMessage({ messageText: response.errorMessage, messageType: "error" });
          }
          return [];
        }
      } else {
        showCustomMessage({
          messageText: "Valid inputs not selected to load affiliations",
          messageType: "error",
        });
        return [];
      }
    },
    async loadAffiliations() {
      const affiliationsData = await this.loadAffiliationsData(false);
      this.AffiliationOptions = affiliationsData as Array<IdNamePair>;
    },
    async loadDetailedAffiliations() {
      return (await this.loadAffiliationsData(true)) as Array<AffiliationDisplay>;
    },
    async onAffiliationChange(affiliationId: number) {
      if (!this.AffiliationOptions) {
        return;
      }
      const matchedAffiliation = this.AffiliationOptions.find(
        (affiliation) => affiliation.Id === affiliationId,
      );
      if (matchedAffiliation) {
        this.SelectedAffiliation = matchedAffiliation;
        this.ReloadAffiliationsKey += 1;
      }
    },
    getUserRoleOptions() {
      let userRoleOptions = getIdNamePairsFromEnum(UserRoleIdsEnum, true);
      const sessionStore = useSessionStore();
      const { UserInfo } = storeToRefs(sessionStore);
      if (!AllAdminRoles.includes(UserInfo.value.UserRole)) {
        userRoleOptions = userRoleOptions.filter((userRole) => {
          return AddEditUserRoleIds.includes(userRole.Id);
        });
      }
      return userRoleOptions;
    },
  },
});
