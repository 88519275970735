<template>
  <div>
    <div
      v-if="closeButtonTarget == 'dashboard'"
      :class="`button button-outline primary-link p-0`"
      @click="gotoDashboard"
    >
      <i class="bi bi-x fs-l lh-none"></i>
    </div>
    <div
      v-else-if="closeButtonTarget == 'previous'"
      :class="`button button-outline primary-link p-0`"
      @click="gotoPrevious"
    >
      <i class="bi bi-arrow-left-short fs-l lh-none"></i>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { gotoDashboard, gotoPrevious } from "@/methods/utils";

export default defineComponent({
  name: "TheCloseButton",
  props: {
    closeButtonTarget: { type: String, default: "dashboard" },
  },
  setup() {
    return { gotoDashboard, gotoPrevious };
  },
});
</script>
