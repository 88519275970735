import adminRoutes from "@/routes/adminRoutes";
import aemRoutes from "@/routes/aemRoutes";
import affiliationRoutes from "@/routes/affiliationRoutes";
import commonRoutes from "@/routes/commonRoutes";
import contentRoutes from "@/routes/contentRoutes";
import studentRoutes from "@/routes/studentRoutes";
import tutoringRoutes from "@/routes/tutoringRoutes";
import { useSessionStore } from "@/stores/sessionStore";
import { useSpeedlabsStore } from "@/stores/speedlabsStore";
import { HttpStatusCode } from "axios";
import { storeToRefs } from "pinia";
import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

const missingRoute = [{ path: "/:pathMatch(.*)*", redirect: "/" }];
const routes = commonRoutes
  .concat(studentRoutes)
  .concat(affiliationRoutes)
  .concat(adminRoutes)
  .concat(contentRoutes)
  .concat(aemRoutes)
  .concat(tutoringRoutes)
  .concat(missingRoute);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes as Array<RouteRecordRaw>,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && !to.meta.scrollToTop) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach(async (to, from) => {
  const sessionStore = useSessionStore();
  const pingStatusCode = await sessionStore.pingApiServer();
  if (to.name === "UnderMaintenance" || to.name === "TooManyRequests") {
    return pingStatusCode === HttpStatusCode.Ok ? { name: "LandingPage" } : true;
  }
  if (pingStatusCode !== HttpStatusCode.Ok) {
    return pingStatusCode === HttpStatusCode.TooManyRequests
      ? { name: "TooManyRequests" }
      : { name: "UnderMaintenance" };
  }
  if (from.name === undefined && to.name !== "Logout") {
    const speedlabsStore = useSpeedlabsStore();
    await speedlabsStore.loadSpeedlabsConfig();
    const isValidSession = await sessionStore.validateLoggedInSession();
    if (!isValidSession) {
      return false;
    }
  }
  let isValidNavigation = false;
  if (to.meta.requiresAuth) {
    if (sessionStore.IsLoggedIn) {
      const allowedRoles = (to.meta.allowedRoles as Array<string>) || [];
      const userRole = sessionStore.UserInfo.UserRole;
      if (allowedRoles.includes(userRole)) {
        isValidNavigation = true;
      } else {
        return { name: "Unauthorized" };
      }
    } else {
      return { name: "LandingPage" };
    }
  } else if (to.meta.requiresUnAuth && sessionStore.IsLoggedIn && sessionStore.navbarMenu) {
    return { name: sessionStore.navbarMenu.DefaultView };
  } else {
    isValidNavigation = true;
  }
  if (isValidNavigation) {
    const { hideNavbar, hideFooter } = storeToRefs(sessionStore);
    if (sessionStore.IsLoggedIn && to.name !== "Logout") {
      await sessionStore.updateSessionLastAccessed();
    }
    hideNavbar.value = to.meta.hideNavbar ? to.meta.hideNavbar : false;
    hideFooter.value = to.meta.hideFooter ? to.meta.hideFooter : false;
  }
});

export default router;
